<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" class="integration-collapse-item" name="p1">
      <template v-slot:title>
        <span>
          <span>Фильтр</span>
          <el-button  class="ml-5"
              plain
              size="mini"
              type="default" 
              @click.stop="clearFilters" 
              style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </span>
      </template>
      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="6" class="ml-0 filter--container">Дата и время:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Журнал интеграции:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Тип сообщения:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Интеграция:</el-col>
      </el-row>
      <el-row class="m-0 mb-3 filter">
        <el-col :span="6" class="ml-0 filter--container">
                      <el-date-picker
                        style="width:100%;"
                        size="small"
                        v-model="filterModel.periodIntegrationTimeRange"
                        type="datetimerange"
                        range-separator="-"
                        placeholder="дд.мм.гггг чч:мм"
                        format="dd.MM.yyyy HH:mm"
                        start-placeholder="с"
                        end-placeholder="по">
                      </el-date-picker>        
        </el-col>
        <el-col :span="6" class="ml-0 filter--container">
          <remote-search-input
            v-model="filterModel.integrationJournalId"
            searchUrl="/api/Integration/SearchByNumber"
            returnProp="id"
          />
        </el-col>
        <el-col :span="6" class="ml-0 filter--container">
          <!-- <span class="mr-2">Тип:</span> -->
          <el-select multiple v-model="filterModel.changeTypes" clearable size="small">
            <el-option
              v-for="item in integrationChangesTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="ml-0 filter--container">
          <!-- <span class="mr-2">Наименование:</span> -->
          <el-select v-model="filterModel.integrationId" clearable size="small">
            <el-option
              v-for="item in integrations"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="filter--container ml-2 ">
          <el-button @click="clearFilters" type="text" style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';

export default {
  name: 'IntegrationChangesFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  components: { RemoteSearchInput },
  data() {
    return {
      filter: [''],
      primaryEquipmentTypes: [],
    };
  },
  created() {
    if (UserSettingsService.has('integrationChangesFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['integrationChangesTypes', 'integrations']),
    ...mapGetters('identity', ['user'])
  },
  methods: {
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('integrationChangesFilter_filter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('integrationChangesFilter_filter_open', this.user.id);
      }
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        this.$emit('active-change', true);
       } else {
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    }
  }
};
</script>

<style scoped lang="scss">
.filter--container{
  align-items: flex-start;
  span {
    min-width: 8rem;
  }
}
.middle-align{
  align-items:center;
}
.integration-collapse-item :deep(.el-collapse-item__content) {
  padding-bottom: 0 !important;
}
</style>
