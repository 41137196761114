<template>
  <div class="my-flex-container-column">
    <el-row class="m-0" :gutter="20">
      <el-col :span="12">
        <pagination style="text-align: center"
                    :total-count="totalCount"
                    :page-size.sync="pageSize"
                    :current-page.sync="pageNumber"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"/>
      </el-col>
    </el-row>
    <div>
      <!-- <span v-if="!this.filterModel.skipEoObject"
            class="total_items">Всего: {{ this.linesCount }}</span> -->
    </div>
    <div v-loading="loading" class="my-flex-data-content">
    <el-table style="width: 100%;"
              v-loading="loading"
              :data="integrationChanges"
              :default-sort = "{prop: 'integrationJournalNumber', order: 'ascending'}"
              @sort-change="handleSortChange">
      <el-table-column label="" width="50">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-search"
                      @click="handleView(scope.$index, scope.row)"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="integrationJournalNumber"
                       label="Номер"
                       sortable="custom"
                       min-width="2" /> 
      <el-table-column prop="date"
                       label="Дата начала"
                       sortable="custom"
                       min-width="5"
                       :formatter="dateTimeRenderer" />
      <el-table-column prop="shortDescription"
                       label="Описание короткое"
                       sortable="custom"
                       min-width="7" /> 
      <el-table-column prop="typeName"
                       label="Тип сообщения"
                       sortable="custom"
                       min-width="7" />
    </el-table>
      <integration-changes-modal @on-update="getIntegrations()" @on-create="getIntegrations()"
                           @on-delete="getIntegrations()"
                           v-model="dialogVisible"
                           :integrationChange="dialogIntegrationChange"></integration-changes-modal>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import IntegrationChangesModal from '@/views/Administration/Integrations/IntegrationChanges/IntegrationChangesModal';
import integrationsApi from '@/api/administration/integrations';
import pagination from '@/components/Ui/Pagination';
import tableResize from '@/mixins/tableResize.js';
import formatters from '@/mixins/formatters';
import dateHelpers from '@/mixins/dateHelpers';

export default {
  name: 'IntegrationChangesTable',
  mixins: [tableResize, formatters, dateHelpers],
  props: {
    filterModel: {
      type: Object,
default: () => {
      }
    },
    filterActive: { type: Boolean, default: () => true },
  },
  components: {
    pagination, IntegrationChangesModal
  },
  data() {
    return {
      expand: false,
      loading: false,
      integrationChanges: [{ date: '2021-01-01', typeId: 0, shortDescription: '' }],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'date',
      sortDesc: true,
      dialogVisible: false,
      dialogIntegrationChange: {},
      mergeMode: false,
      mergingItems: [],
      dialogMergeVisible: false,
      linesCount: 0,
    };
  },
  watch: {
    filterModel: {
      handler() {
        // console.log('this.$refs.tree', this.$refs.tree);
        // this.$refs.tree.defaultExpandAll = true;
        this.getIntegrations();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
  },
  async mounted() {
    await this.getIntegrations();
  },
  methods: {
    async getIntegrations() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        integrationJournalId: this.filterModel.integrationJournalId,
        integrationId: this.filterModel.integrationId,
        changeTypes: this.filterModel.changeTypes,
        dateFrom: this.filterModel.periodIntegrationTimeRange ?  moment(this.dateSetter(this.filterModel.periodIntegrationTimeRange[0])).local().format('YYYY-MM-DD HH:mm:ss') : '',
        dateTo: this.filterModel.periodIntegrationTimeRange ?  moment(this.dateSetter(this.filterModel.periodIntegrationTimeRange[1])).local().format('YYYY-MM-DD HH:mm:ss') : '',
      };
      const res = await integrationsApi.getChanges(params);
      if (res.data) {
        this.integrationChanges = res.data.items;
        this.totalCount = res.data.totalCount;
        this.linesCount = res.data.treeItemsCount;
        this.defaultExpandedKeys = this.integrationChanges.map((i) => i.id);

        this.loading = false;
      }
    },
    handleView(index, integrationChange) {
      this.dialogIntegrationChange = cloneDeep(integrationChange);
      this.dialogVisible = true;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getIntegrations();
    },
    async handleCurrentChange() {
      await this.getIntegrations();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getIntegrations();
    }
  }
};
</script>

<style scoped>

.total_items {
  float: right;
  color: #909399;
  font-family: Montserrat-Regular;
  font-size: 14px;
  margin-top: 12px;
  margin-right: 8px;
}
</style>
