<template>
  <el-dialog
    v-if="value"
    :visible.sync="dialogVisible"
    top="2rem"
    width="40%"
    class="nsi-energy-object"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Лог
   </div>
    <el-form :rules="rules" v-loading="loading" ref="integrationChangeForm" :model="integrationItem" label-width="12rem">
      <el-form-item prop="className" label="Дата:">
        <el-date-picker v-model="integrationItem.date"
                        v-mask="'##.##.#### ##:##'"
                        size="mini"
                        style="width: 100%"
                        type="datetime"
                        range-separator="-"
                        placeholder="дд.мм.гггг чч:мм"
                        format="dd.MM.yyyy HH:mm"
                        :picker-options="datePickerOptions"
                        disabled>
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="typeId" label="Тип сообщения:">
        <el-select v-model="integrationItem.typeId" size="large" filterable clearable disabled>
          <el-option
            v-for="item in integrationChangesTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Описание короткое:" prop="shortDescription">
        <el-input id="escription" type="textarea" disabled :rows="3"
                  placeholder="" v-model="integrationItem.shortDescription"></el-input>
      </el-form-item>
      <el-form-item label="Описание полное:" prop="description">
        <el-input id="escription" type="textarea" disabled :rows="15"
                  placeholder="" v-model="integrationItem.description"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import integrationChangeApi from '@/api/administration/integrations';

export default {
  name: 'IntegrationChangesModal',
  props: ['value', 'integrationChange'],
  // mixins: [validationRules],
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    ...mapGetters('dictionaries', ['integrationChangesTypes']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    dialogVisible: {
      get() {
        this.getIntegrationChangeModel(this.integrationChange.id);
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  // components: { SaveIcon },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        className: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        regionId: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        analysisIds: [
          { validator: this.isUUIDList, trigger: 'blur' }
        ],
        mRid: [ 
          { validator: this.isUUID, trigger: 'blur' }
        ]
      },
      integrationItem: ''
    };
  },
  async mounted() {
    // this.$root.$on('addEvent', (x) => {
    //   this.editMode = x.editMode;
    // });
  },
  methods: {
    async getIntegrationChangeModel(id) {
      const res = await integrationChangeApi.getChangeItem(id);
      this.integrationItem = res.data;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    async validateForm() {
      const valid = await this.$refs.integrationChangeForm.validate();
      return valid;
    }
  },
};
</script>

<style scoped>

</style>
