<template>
  <div class="my-flex-container-column">
    <integration-changes-filter @active-change="filterActive = $event" :filter-model="filterModel" @clear-filters="clearFilters"/>
    <integration-changes-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>
<script>
import integrationChangesTable from '@/views/Administration/Integrations/IntegrationChanges/IntegrationChangesTable';
import integrationChangesFilter from '@/views/Administration/Integrations/IntegrationChanges/IntegrationChangesFilter';

export default {
  name: 'IntegrationChanges',
  components: { integrationChangesTable, integrationChangesFilter },
  data() {
    return {
      filterModel: {
        changeTypes: [],
        integrationId: 1
      },
      filterActive: true
    };
  },
  methods: {
    clearFilters() {
      this.filterModel = {
        changeTypes: [],
        integrationId: 1
      };
    }
  }
};
</script>

<style scoped>

</style>
