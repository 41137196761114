<template>
  <div class="my-flex-container-column">
    <integrations-journal-filter @active-change="filterActive = $event" :filter-model="filterModel" @clear-filters="clearFilters"/>
    <integrations-journal-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>
<script>
import integrationsJournalTable from '@/views/Administration/Integrations/IntegrationsJournal/IntegrationsJournalTable';
import integrationsJournalFilter from '@/views/Administration/Integrations/IntegrationsJournal/IntegrationsJournalFilter';

export default {
  name: 'IntegrationsJournal',
  components: { integrationsJournalTable, integrationsJournalFilter },
  data() {
    return {
      filterModel: {
        startDtFrom: '',
        startDtTo: '',
        endDtTo: '',
        endDtFrom: '',
        integrations: [1],
        statuses: [],
        extNumber: '',
        integrationId: 1
      },
      filterActive: true
    };
  },
  methods: {
    clearFilters() {
      this.filterModel = {
        startDtFrom: '',
        startDtTo: '',
        endDtTo: '',
        endDtFrom: '',
        skipEoObject: false,
        isLocalNsiFilter: false,
        integrations: [1],
        statuses: [],
        extNumber: '',
        integrationId: 1
      };
    }
  }
};
</script>

<style scoped>

</style>
